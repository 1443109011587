@use "../../scss/mixins/" as *;

.card {
    &__container {
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 5px 25px rgba($color: #000000, $alpha: 0.1);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        margin-bottom: 5rem;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    &--alternative {
        .container {
            @include tablet_min {
                flex-direction: row-reverse;
            }
        }
    }

    &__thumbnail {
        width: 100%;

        img {
            width: 100%;
            @include tablet_min {
                height: calc(((100vw / 3) - (2rem * 3)) * (3 / 4.5));
                max-height: 230px;
            }
            object-fit: cover;
            overflow: hidden;
        }

        &--cols2 {
            img {
                @include tablet_min {
                    height: calc(((100vw / 2) - (2rem * 3)) * (3 / 4.5));
                    max-height: 300px;
                }
                object-fit: contain;
            }
        }

        &--big {
            @include tablet_min {
                max-width: 1280px;
                transform: translateY(-50%);
                width: 50vw;
            }
        }
    }

    &__actions {
        text-align: center;
        padding-bottom: 2rem;
    }

    &--heading {
        background-color: white;
        .container {
            display: block;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .card__thumbnail {
            img {
                margin-bottom: 1rem;
                transform: scale(1.5);
                height: auto;
                max-height: unset;
            }

            @include tablet_min {
                margin: auto;
                max-width: 700px;
                transform: none;
                width: 100%;
            }
        }
    }
}

.card__text {
    width: auto;
    width: 100%;
    padding: 0 1rem;
    flex: 1;

    &__title {
        color: var(--titlePrimary);
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        text-align: center;
        @include tablet_min {
            text-align: left;
        }
    }

    p {
        margin: 1rem 0;
    }

    &__title {
        text-align: left;
        @include tablet_min {
            text-align: center;
        }
    }

    & .tags {
        text-align: left;
        @include tablet_min {
            text-align: center;
        }
    }
    .card__actions {
        @include tablet_min {
            text-align: center;
        }
    }
}
