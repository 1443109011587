@use "../variables/" as *;
@use "../mixins/" as *;

.img-responsive {
   width: 100%;
   max-width: 100%;
}

.full-width {
   width: 100%;
}
.spacer {
   padding: 3rem;
   width: 100%;
   &--small {
      padding: 2rem;
   }
   &--min-space {
      padding: 0.1px;
   }
}

.hidden-phone {
   display: none;
   @include tablet_min {
      display: initial;
   }
}

.hidden-tablet-min {
   @include tablet_min {
      display: none;
   }
}
.hidden-tablet {
   @include tablet {
      display: none;
   }
}
.hidden-laptop {
   @include laptop {
      display: none;
   }
}
.hidden-desktop {
   @include desktop {
      display: none;
   }
}

.crop {
   overflow: hidden;
}
