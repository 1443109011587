@use "../../mixins/" as *;
@use "../../variables/" as *;

.tags {
   padding: 7px 0;
   text-align: center;
   @include tablet_min {
      text-align: left;
   }
   .tag {
      &__span {
         background-color: $tagColour;
         border-radius: 100px;
         color: $tagTextColor;
         display: inline-block;
         font-size: 0.7rem;
         font-weight: 700;
         margin-right: 5px;
         padding: 3px 10px;
         &:last-child {
            margin-right: 0;
         }
      }
   }
}
