@use "../variables/" as *;

/* loader */
.loader {
    width: 100%;
    height: 15px;
    position: fixed;
    top: 0;
    left: 0;
    color: #666;
    font-size: x-large;
    &::after {
        top: 0;
        content: "";
        display: block;
        width: 0%;
        height: 5px;
        position: absolute;
        animation-fill-mode: forwards;
    }
    &.loader1::after {
        background: $nasuPurple;
        animation: load 1s ease-in-out;
    }
    &.loader2::after {
        background: $joustBlue;
        animation: load 0.75s ease-in-out;
        animation-delay: 0.25s;
    }
    &.loader3::after {
        background: $jadeDust;
        animation: load 0.5s ease-in-out;
        animation-delay: 0.5s;
    }
    &.loader4::after {
        background: $wildCaribbeanGreen;
        animation: load 0.25s ease-in-out;
        animation-delay: 0.75s;
    }
}
