$phone-width: 640px;
$tablet-width: 768px;
$laptop-width: 1200px;
$desktop-width: 1600px;

:root {
   --max-width-t: #{$tablet-width};
   --max-width-l: #{$laptop-width};
   --max-width-d: #{$desktop-width};
}

@mixin phone {
   @media (max-width: #{$phone-width}) {
      @content;
   }
}

@mixin tablet_min {
   @media (min-width: #{$tablet-width}) {
      @content;
   }
}

@mixin tablet {
   @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
      @content;
   }
}

@mixin laptop {
   @media (min-width: #{$laptop-width}) {
      @content;
   }
}
@mixin desktop {
   @media (min-width: #{$desktop-width}) {
      @content;
   }
}
