@use "../mixins/" as *;

.heading {
   font-weight: 600;
   letter-spacing: -0.05em;
   &--jumbo {
      font-size: 7rem;
      font-weight: 700;
      @include phone {
         font-size: clamp(2rem, 20vmin, 7rem);
      }
   }
   &--zero-margin {
      margin: 0;
   }
   &--small-margin {
      margin: 0.4em 0;
   }
}
.subheading {
   margin: 1rem 0;
   font-weight: 300;
   line-height: 1.5;
   letter-spacing: -0.02em;
}

h1,
.heading--primary {
   font-size: 6rem;
}

h2,
.heading--secondary {
   font-size: 4.5rem;
}

h3,
.heading--tertiary {
   font-size: 3rem;
}

h4,
.heading--quaternary {
   font-size: 2rem;
}

h5,
.heading--quinary {
   font-size: 1.5rem;
}
