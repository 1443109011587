@use "../../scss/mixins/" as *;
.container {
   width: 100%;
   max-width: var(--max-width-l);
   padding: 0 1rem;
   margin: auto;

   @include tablet {
      padding: 0 1rem;
   }

   @include desktop {
      padding: 0 1rem;
   }

   &--small {
      max-width: var(--max-width-t);
   }
   &--large {
      max-width: var(--max-width-d);
   }

   &.columns {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      @include tablet_min {
         flex-direction: row;

         &--2cols > * {
            max-width: 50%;
         }
         &--3cols > * {
            max-width: 33.33%;
         }
      }
   }
   &.text-cols {
      gap: 5%;
      &--2cols {
         @include tablet_min {
            columns: 2;
            p:first-of-type {
               margin-top: 0;
            }
         }
      }
   }
   &--flex-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      width: 100%;

      @include tablet_min {
         flex-direction: row;
      }
   }
}

@include laptop {
   @for $i from 1 through 12 {
      .col-#{$i} {
         max-width: calc(100% * (#{$i} / 12));
         width: 100%;
      }
   }
}
