:root {
   --accentColourHover: #48dbfb;
   --accentColour: #31e8b8;
   --wildCaribbeanGreen: #1dd1a1;
   --jadeDust: #00d2d3;
   --aquaVelvet: #01a3a4;
   --joustBlue: #54a0ff;
   --nasuPurple: #5f27cd;
   --bluebell: #341f97;
   --fuelTown: #576574;
   --tagColour: #796bdb;
   --primaryDark: #2b3233;
   --textPrimary: var(--primaryDark);
   --titlePrimary: var(--primaryDark);
   --light-bg: #f4f6f9;
}

/* Color vars */

$accentColourHover: var(--accentColourHover);
$accentColour: var(--accentColour);
$wildCaribbeanGreen: var(--wildCaribbeanGreen);
$jadeDust: var(--jadeDust);
$aquaVelvet: var(--aquaVelvet);
$joustBlue: var(--joustBlue);
$nasuPurple: var(--nasuPurple);
$bluebell: var(--bluebell);
$tagColour: var(--tagColour);
$primaryDark: var(--primaryDark);

/*  Applied to */

$textPrimary: var(--textPrimary);
$titlePrimary: var(--titlePrimary);
$tagTextColor: rgba(white, 1);
$buttonPrimary: $accentColour;
$buttonPrimaryText: $textPrimary;
$buttonPrimaryHover: white;
$buttonPrimaryBorder: $accentColour;
$modalOverlay: rgba(#222f3e, 0.8);
