@use "../../scss/mixins/" as *;

.section {
    width: 100%;
    min-height: 100vh;

    &--min-height-0 {
        min-height: 0;
    }

    &__title {
        margin: 5rem 0;
        color: var(--titlePrimary);

        &--center {
            text-align: center;
            @include laptop {
                font-size: clamp(2rem, 10vmin, 7rem);
            }
        }
    }
    &--alt {
        background: #ddecfe;
        background: linear-gradient(
                160deg,
                rgba(221, 236, 254, 0.8) 0%,
                rgba(221, 236, 254, 0.5) 100%
            ),
            linear-gradient(
                150deg,
                rgba(15, 142, 216, 0.4) 0%,
                rgba(57, 168, 190, 0) 30%
            ),
            linear-gradient(
                201deg,
                rgba(211, 37, 153, 0.4) 0%,
                rgba(119, 33, 216, 0) 30%
            ),
            linear-gradient(
                345deg,
                rgba(42, 231, 177, 0.4) 0%,
                rgba(32, 220, 227, 0) 30%
            ),
            linear-gradient(
                25deg,
                rgba(255, 226, 10, 0.4) 0%,
                rgba(227, 159, 32, 0) 30%
            );
    }
    .sub-section {
        &__title {
            margin: 1em 0;
            @include laptop {
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }
}

.profile-pic {
    width: 100%;
    max-width: 200px;
    margin: auto;
    margin-top: 2rem;
    &__img {
        border-radius: 300px;
        object-fit: cover;
    }
}
